const welcomeTimeline = gsap.timeline();

welcomeTimeline.add('start');
welcomeTimeline.to(
  '#left-arm',
  0.5,
  { x: 10, yoyo: true, repeat: -1 },
  'start'
);
welcomeTimeline.to(
  '#right-arm',
  0.8,
  { x: -10, yoyo: true, repeat: -1 },
  'start'
);
welcomeTimeline.staggerFrom(
  'g[id^=line]',
  0.5,
  { yoyo: true, opacity: 0, repeat: -1 },
  1
);
welcomeTimeline.to(
  '#arrow',
  0.5,
  {
    yoyo: true,
    y: -5,
    ease: Bounce.easeInOut,
    repeat: -1,
  },
  'start+=1'
);
